// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-js": () => import("./../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partner-with-prime-js": () => import("./../src/pages/partner-with-prime.js" /* webpackChunkName: "component---src-pages-partner-with-prime-js" */),
  "component---src-pages-request-portal-access-js": () => import("./../src/pages/request-portal-access.js" /* webpackChunkName: "component---src-pages-request-portal-access-js" */)
}

