module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#003366","theme_color":"#003366","display":"minimal-ui","icon":"src/images/prime-logo-manifest.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f32309437be48b5005db2a41603da5a0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-45815575-9","head":true,"anonymize":true,"respectDNT":true,"cookieDomain":"primephilippines.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Poppins:300,500,700","Open Sans","sans-serif"]},"custom":{"families":["Proxima Nova"],"urls":["/fonts/proxima-nova/fonts.css"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
